<template>
  <div class="w-100 min-width-200 mx-5 d-flex flex-column align-center">
    <div class="d-flex flex-row w-100 justify-between mt-5 mb-3">
      <span
        style="color: #6038F7; font-size: 20px; font-family: 'Poppins-Medium'"
        >Blogs</span
      >
      <v-btn
        color="#B5E539"
        small
        @click="goToCreateBlog"
        v-if="profileType == 'Agency'"
      >
        Create
      </v-btn>
    </div>
    <!-- 
    <div style="width: 100%">
      <v-btn
        @click="goToCreateBlog"
        v-if="profileType == 'Agency'"
        class="mt-5 ml-3"
        >Create Blog</v-btn
      >
    </div> -->
    <div class="pb-5 w-100">
      <v-progress-circular
        indeterminate
        color="amber"
        class="mt-6 w-100"
        v-if="loading && !blogs.length"
      />
      <div cols="12" v-for="blog in blogs" :key="blog._id">
        <blog-card :blog="blog" class="w-100 mb-3" />
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BlogCard from "./BlogCard.vue";
export default {
  components: { BlogCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions("blog", {
      fetchAllBlogs: "fetchAllBlogs",
      loadMore: "loadMoreEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadMoreEvents() {
      this.loadingMore = true;
      this.loadMore({ skip: this.events.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToCreateBlog() {
      this.$router.push({ name: "create-blog" });
    },
  },
  computed: {
    ...mapState("auth", {
      profileType: "type",
    }),
    ...mapGetters("blog", {
      blogs: "getAllBlogs",
    }),
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllBlogs()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped></style>
